import createHttp from '@dmm/create-http';
import * as utils from '../store/utils';

const HEADER_PASSTHRU_LIST = ['x-forwarded-for', 'x-type-portal', 'x-targeted-ads-consent', 'isbot', 'x-amzn-waf-isbot', 'x-datadome-isbot', 'x-datadome-botname', 'user-agent'];

const MAX_HTTP_RETRY = 4;
const RETRY_CODES = [500, 502, 503, 504, 599];
const ROW_ERROR = true;

// The following is to fix an IE11 bug with axios and should be removed if IE11 goes away
const _headerFix = http => http.setHeader('Accept', 'application/json');

const getReactSecureProxyBaseUrl = () => process.env.REACT_APP_HOST.includes('localhost') ? process.env.REACT_APP_PUBLIC_API_ENDPOINT : process.env.REACT_APP_HOST;

//Passthru some of the request headers downstream
const _headerPassthru = (http, headers = {}) => Object.keys(headers)
  .filter(name => HEADER_PASSTHRU_LIST.includes(name))
  .forEach(name => http.setHeader(name, headers[name]));

const _setHeaders = (http, headers) => {
  _headerFix(http);
  _headerPassthru(http, headers);
  return http;
};

const _configureHttp = (http, headers) => {
  http.defaults.retry = MAX_HTTP_RETRY;
  return _setHeaders(http, headers);
};

/**
 * Returns an HTTP client instance configured for server-side rendering requests.
 * @param {object} headers - Headers to be set for the HTTP requests.
 * @returns {object} Configured HTTP client instance for server-side rendering.
 */
export const getApiClient = headers => _configureHttp(createHttp({
  baseURL: process.env.API_ENDPOINT, retryFor: RETRY_CODES, returnRowError: ROW_ERROR }), headers);

/**
 * Returns an HTTP client instance configured for client-side rendering requests that do not start with '/app/*'.
 * @param {object} headers - Headers to be set for the HTTP requests.
 * @returns {object} Configured HTTP client instance for non-'/app/*' client-side rendering.
 */
export const getReactClient = headers => _configureHttp(createHttp({
  baseURL: process.env.REACT_APP_PUBLIC_API_ENDPOINT,
  apikey: process.env.REACT_APP_PUBLIC_API_KEY,
  retryFor: RETRY_CODES,
  returnRowError: ROW_ERROR
}), headers);

/**
 * Returns an HTTP client instance configured for client-side rendering requests starting with '/app/'.
 * @param {object} headers - Headers to be set for the HTTP requests.
 * @returns {object} Configured HTTP client instance for '/app/' client-side rendering.
 */
export const getReactSecureProxyClient = headers => _configureHttp(createHttp({
  baseURL: getReactSecureProxyBaseUrl(),
  apikey: process.env.REACT_APP_PUBLIC_API_KEY,
  retryFor: RETRY_CODES,
  returnRowError: ROW_ERROR
}), headers);


/**
 * Returns an HTTP client instance configured for client-side rendering requests requiring authentication (JWT).
 * @param {object} headers - Headers to be set for the HTTP requests.
 * @returns {object} Configured HTTP client instance for authenticated client-side rendering.
 */
export const getSecureHttpClient = headers => _configureHttp(createHttp({
  baseURL: process.env.REACT_APP_PUBLIC_API_ENDPOINT,
  retryFor: RETRY_CODES,
  returnRowError: ROW_ERROR
}), headers);

export const getHttpClient = headers => utils.isServer() ? getApiClient(headers) : getReactClient(headers);

export const getSecureProxyHttpClient = headers => utils.isServer() ? getApiClient(headers) : getReactSecureProxyClient(headers);
